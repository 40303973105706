import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'

const GuvenlikBeriyeriPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Güvenlik Bariyeri Hasır Teli İmalatı - Erez Kaynak'
        description='Firmamız çift tel olarak yada tek tel olmak üzere siyah ham tel yada galvaniz telden güvenlik bariyeri hasır teli imalatı yapmaktadır. Güvenlik bariyeri hasır tel ebatları hazırda 100x250cm yada 100x125cm ebatlarında imalat yapılabilmektedir. Alt kısmına 20cm genişliğinde topuk sacı kaynatılabilmektedir.'
        keywords='Güvenlik Bariyeri Hasır Teli İmalatı,güvenlik bariyeri,sarı güvenlik bariyerleri,kat güvenlik bariyeri,inşaat güvenlik bariyerleri,güvenlik bariyer teli,güvenlik bariyeri fiyatları'
        url='guvenlik-bariyeri-hasir-teli-imalati'
        imgurl={getImageByName('guvenlikbariyeri').sm.src}
      />
      <ImgLightBox
        ref={lightBoxRef}
        gallery={[
          getImageByName('guvenlikbariyeri').original.src,
          getImageByName('panelcithasirtel').original.src,
          getImageByName('guvenlikbariyeri2').original.src,
        ]}
      />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Güvenlik Bariyeri Hasır Teli İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                <strong>Güvenlik beriyeri hasır teli</strong> iş güvenliği için inşaat alanlarında, depo giriş ve tehlikeli kenar-uçlarda
                geçici olarak insan yada bir eşyanın düşmesine engel olmak amacıyla kullanılmaktadır.
              </p>
              <p>
                Firmamız çift tel olarak ya da tek tel olmak üzere siyah ham tel ya da galvaniz telden{' '}
                <strong>güvenlik bariyeri hasır teli</strong> imalatı yapmaktadır.
              </p>
              <p>
                <strong>Güvenlik bariyeri hasır tel</strong> ebatları hazırda 100x250cm yada 100x125cm ebatlarında imalat yapılabilmektedir.
              </p>
              <p>
                <strong>Güvenlik bariyeri hasır teli</strong> alt kısmına 20cm genişliğinde topuk sacı kaynatılabilmektedir.
              </p>
            </ContentText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('guvenlikbariyeri')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            {/* <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box> */}
          </Grid>
        </Grid>
        <ContentText>
          <p>
            Güvenlik bariyeri hasır tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile
            hasır teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip,
            çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
          </p>
          <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
        </ContentText>
        <Box textAlign='center' mt={3}>
          <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
        </Box>
      </Box>
      <Grid container justify='center' spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          <ImgWrapper onClick={() => lightBoxRef.current.openLightBox(1)}>
            <MediumImage lazyHeight={500} img={getImageByName('panelcithasirtel')} alt='Özel hasır tel imalatı' />
          </ImgWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          <ImgWrapper onClick={() => lightBoxRef.current.openLightBox(2)}>
            <MediumImage lazyHeight={500} img={getImageByName('guvenlikbariyeri2')} alt='Özel hasır tel imalatı' />
          </ImgWrapper>
        </Grid>
      </Grid>
      <GeneralCarousel />
    </>
  )
}

export default GuvenlikBeriyeriPage
